<template>
  <div>

    <div data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" class="navbar-component _2 w-nav">
      <div class="navbar-container w-container">
        <a id="w-node-_2bc7849c-fc53-2a49-059f-aa960ee8e78d-0ee8e78b" @click="goToHome" class="navbar-logo-link no-rizer w-nav-brand"><img src="../../../../../public/images/brokton.svg" loading="lazy" alt="" class="navbar-logo"></a>
      </div>
    </div>
    <div class="section color mid-line">
      <div v-show="showProgressBar" class="container last form one w-container">
        <div class="form-regidet w-form">
          <div class="wrap-steps">
            <a @click="previousPage" class="arrow-left w-inline-block"><img src="../../../../../public/images/Union.svg" loading="lazy" alt="" class="arrow"></a>
            <div class="step">
              <div class="step-number like">
                <div>1</div>
              </div>
            </div>
            <div class="step">
              <div class="step-number like">
                <div class="text-block-7">2</div>
              </div>
            </div>
            <div class="step">
              <div class="step-number like">
                <div class="text-block-8">3</div>
              </div>
            </div>
            <div class="step">
              <div class="step-number active">
                <div class="text-block-9">4</div>
                <div data-w-id="a1680b49-2005-863e-9cc8-d83ed7d2e3cb" class="pulse"></div>
              </div>
            </div>
          </div>
          <form name="wf-form-signup-2" data-name="signup" method="get" data-ms-form="signup" class="form-input-regidet">
            <div class="form-progress-bar">
              <h1 v-if="!finalCountdownStarted" class="heading-form">Проверка данных</h1>
              <h1 v-else class="heading-form">Всё готово!</h1>
              <div class="lotti-number">

                <div ref="animationContainer" style="width: 250px; height: 250px;"></div>

                <div class="progress-bar">
                  <span>{{ Math.floor(progress) }}%</span>
                </div>
              </div>
              <div v-if="!finalCountdownStarted" class="text-classic _2">Не закрывайте вкладку и не уходите с нее.</div>

              <div v-else class="text-classic _2">
                Через <span class="time-down">{{ finalCountdown }}</span> секунд покажем результат
              </div>
            </div>
            <div class="line-progress step-4"></div>
          </form>
        </div>
      </div>
      <div v-show="!showProgressBar" ref="containerPartners" class="container last form one w-container">
        <div class="form-regidet w-form">
          <form name="wf-form-signup-2" data-name="signup" method="get" data-ms-form="signup" class="form-input-regidet">
            <div class="hide-result">
              <div class="tittle-form middle"><img src="../../../../../public/images/page4.svg" loading="lazy" alt="" class="icon center">
                <h1 class="heading-form middle">К сожалению сейчас <br>не можем выдать вам денег</h1>
              </div>
<!--              <div class="text-classic _2">Но наши партнеры готовы выдать деньги прямо сейчас</div>-->
            </div>
            <a @click="goToPartners" class="button-bust w-button">На главную</a>
            <link rel="prerender" @click="goToPartners">
          </form>
        </div>
      </div>
    </div>

    <Footer />

  </div>
</template>

<script>
import { Footer } from '/src/app/shared/components';
import loadjs from 'loadjs';
import lottie from 'lottie-web';
// Путь к вашему файлу анимации
import animationData from '/public/documents/AnimationLoad.json';

export default {
  name: 'RegistrationStep4',
  data() {
    return {
      progress: 0,
      showProgressBar: false,
      countdown: 5, // Начальное значение обратного отсчета
      animationDuration: 5000, // Длительность анимации в миллисекундах
      finalCountdown: 3, // Обратный отсчет после завершения анимации
      finalCountdownStarted: false, // Флаг для начала финального обратного отсчета
    };
  },
  components: {
    Footer
  },
  mounted() {
    /* грузим jquery и webflow */
    loadjs(
        "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=652921c910bae02d8870dddf",
        {
          success: () => {
            loadjs('./js/webflow.js', {
              success: () => {
              },
              error: () => {
                console.error('Failed to load Webflow');
              }
            });
          },
          error: () => {
            console.error('Failed to load jQuery');
          }
        }
    );

    /* счётчик для процентов и т.п. фигни */
    const scriptUrl = 'https://cdn.jsdelivr.net/npm/@finsweet/attributes-numbercount@1/numbercount.js';
    if (!document.querySelector(`script[src="${scriptUrl}"]`)) {
      const script = document.createElement('script');
      script.async = true;
      script.src = scriptUrl;
      document.head.appendChild(script);
    }

    this.loadAnimation();
  },
  computed: {
  },
  methods: {
    goToLogin() {
      this.$router.push('/login');
    },
    goToHome() {
      this.$router.push('/');
    },
    previousPage() {
      this.$router.push('/registration-step3');
    },
    goToPartners() {
      this.$router.push('/');
    },
    loadAnimation() {
      const options = {
        container: this.$refs.animationContainer,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        animationData: animationData,
      };

      let animation = lottie.loadAnimation(options);

      const defaultDuration = 2;
      const desiredDuration = this.animationDuration / 1000;
      const speed = defaultDuration / desiredDuration;
      animation.setSpeed(speed);

      this.showProgressBar = true;

      this.updateProgress();
      this.startCountdown();

      animation.addEventListener('complete', () => {
        animation.goToAndStop(animation.totalFrames - 1, true); // Остановить анимацию на последнем кадре
        this.onAnimationComplete();
      });
    },
    updateProgress() {
      const startTime = Date.now();
      const interval = setInterval(() => {
        const elapsedTime = Date.now() - startTime;
        this.progress = Math.min(100, (elapsedTime / this.animationDuration) * 100);

        if (this.progress >= 100) {
          clearInterval(interval);
          this.onAnimationComplete();
        }
      }, 100);
    },
    startCountdown() {
      const countdownInterval = setInterval(() => {
        this.countdown -= 1;
        if (this.countdown <= 0) {
          clearInterval(countdownInterval);
        }
      }, 1000);
    },
    onAnimationComplete() {
      this.finalCountdownStarted = true;
      this.startFinalCountdown();
    },
    startFinalCountdown() {
      const finalCountdownInterval = setInterval(() => {
        this.finalCountdown -= 1;
        if (this.finalCountdown <= 0) {
          clearInterval(finalCountdownInterval);
          this.showProgressBar = false;
        }
      }, 1000);
    },
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      window.scrollTo(0, 0);
    });
  },
};
</script>

<style>
</style>
